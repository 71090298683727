import { createContext, useContext, useEffect, useState } from "react";
import axios from "axios";
import env from "config";
import Toast from "components/Toast";
import SsoHandler from "utils/SSOhandler";

const UsersContext = createContext();

const useUsersContext = () => useContext(UsersContext);
const BASE_URL = `${env.API_URL}/v1`;
const FACEBOOK_AUTH = env.FACEBOOK_AUTH

const UsersProvider = ({ children, match }) => {
  const { SSO, checkSSO } = SsoHandler();

  // const socket = useSocketContext();
  const [isActiveSide, setIsActiveSide] = useState(true);
  const [processing, setProcessing] = useState(true);
  const [data, setData] = useState([]);
  const [users, setUsers] = useState([]);
  const [userTyping, setUserTyping] = useState({});
  const [fromPromptMsg, setFromPromptMsg] = useState({});
  const [locationUsers, setLocationusers] = useState();
  const [agencyConfig, setAgencyConfig] = useState({});
  const [agencyInfo, setAgencyInfo] = useState({});
  const [errorType, setErrorType] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [loadingStateslide, setLoadingStateslide] = useState({});

  const [showExploreContent, setShowExploreContent] = useState(true);

  const GetChats = async (SSO_data, page = 0, showLoading = true) => {
    // Fetch 10 records for the first request, and 100 records for subsequent requests
    const limit = page === 0 ? 10 : 50;
    const skip = page === 0 ? 0 : (page - 1) * 100 + 10; // Adjust skip logic

    if (showLoading) setProcessing(true); // Show loading spinner only for the first load

    try {
      // Fetch the chats with the calculated limit and skip values
      const response = await axios.get(
        `${BASE_URL}/chat/filter?locationId=${SSO?.id}&userId=${SSO?.userUUID}&limit=${limit}&skip=${skip}`
      );

      const newChats = response?.data?.data;
      // If page is 0 (first load), set the data; otherwise, append to existing data
      if (page === 0) {
        setData(newChats);
        setUsers(newChats);
        setProcessing(false)
      } else {
        setData((prevData) => [...prevData, ...newChats]);
        setUsers((prevUsers) => [...prevUsers, ...newChats]);
      }

      // Check if there are more chats to load (pagination)
      const totalChats = response.data.totalChats;
      const loadedChats = page === 0 ? 10 : 10 + (page * 100);

      if (loadedChats < totalChats) {
        // Load the next chunk only after the current one finishes
        await GetChats(SSO_data, page + 1, false); // Pass `false` to hide loading spinner for subsequent chunks
      }
    } catch (error) {
      console.error("Error loading chats:", error);
    } finally {
      if (showLoading) setProcessing(false); // Hide the loading spinner after the first load completes
    }

  };
  const GetchatData = async () => {
    const response = await axios.get(
      `${BASE_URL}/chat/filter?locationId=${SSO?.id}&userId=${SSO?.userUUID}`
    );
    const newChats = response?.data?.data;
    return newChats;

  }
  const getLocationUsers = async (SSO_data) => {
    const response = await axios.get(
      `${BASE_URL}/user/chatusers?locationId=${SSO_data?.id}`
    );
    if (response) {
      setLocationusers(response?.data?.data);
    }
  };

  const addYourAIinUser = async (SSO_data) => {
    const response = await axios.put(
      `${BASE_URL}/user/chatuser/yourai?locationId=${SSO_data?.id}&_id=${SSO_data.userUUID}`
    );
    if (response) {
      // setLocationusers(response?.data?.data);
    }
  }

  const getAgencyConfig = async (SSO_data) => {
    const response = await axios.get(
      `${BASE_URL}/agency/agencyconfig?_id=${SSO_data?.agency_id}`
    );

    if (response) {
      setAgencyConfig(response?.data?.data);
      setAgencyInfo(response?.data?.agencyInfo);
    }
  }

  // Function to toggle showExploreContent
  const enableExplore = () => {
    setShowExploreContent(true);
  };

  const enableChat = () => {
    setShowExploreContent(false);
  };

  const [locValid, setlocValid] = useState(data);

  const [toast, setToast] = useState({ message: "", isVisible: false });
  const [toastStyles, setToastStyles] = useState({});

  const showToast = (message, type = '') => {
    if (type === 'err') {
      setToastStyles({ backgroundColor: '#dc3545' })
    }
    if (type === 'success') {
      setToastStyles({ backgroundColor: '#5db371' })
    }
    setToast({ message, isVisible: true });
    setTimeout(() => setToast({ message: "", isVisible: false }), 3000); // Auto-hide after 3 seconds
  };

  const hideToast = () => {
    setToast({ message: "", isVisible: false });
  };

  const hideLeftSidebar = () => {
    setIsActiveSide(false);
  };

  const showLeftSidebar = () => {
    setIsActiveSide(true);
  };

  const handleDeleteContact = async (contactId) => {
    const response = await axios.get(`${BASE_URL}/chat/delete?id=${contactId}`);
    return response;
  };

  const _updateUserProp = (userId, prop, value) => {
    setUsers((users) => {
      const usersCopy = [...users];
      let userIndex = users.findIndex((user) => user.id === userId);
      const userObject = usersCopy[userIndex];
      usersCopy[userIndex] = { ...userObject, [prop]: value };
      setUserTyping(usersCopy);
      return usersCopy;
    });
  };

  const setUserAsTyping = (data) => {
    const { userId } = data;
    setUserTyping(true);
    _updateUserProp(userId, "typing", true);
  };
  const setUserAsNotTyping = (data) => {
    const { userId } = data;
    setUserTyping(false);
    _updateUserProp(userId, "typing", false);
  };

  const setOnFromPrompt = (userId) => {
    setFromPromptMsg(true);
    _updateUserProp(userId, "fromPrompt", true);
  };

  const setOffFromPrompt = (data) => {
    const { userId } = data;
    setFromPromptMsg(false);
    _updateUserProp(userId, "fromPrompt", false);
  };
  function processLinks(response) {
    response = response.replace(/[\[\]\(\)]/g, ' ');

    // Regex to find all URLs in the text
    const urlRegex = /(https?:\/\/[^\s]+)/g;

    // Replace each URL with the corresponding anchor tag
    response = response.replace(urlRegex, (url) => {
      // Remove any trailing parenthesis or unwanted characters from the URL
      url = url.replace(/\)$/, '');

      // Check if the URL is for download (based on the URL or custom logic)
      if (url.includes('Export?id')) {
        // For download links, replace with an anchor tag that triggers download using inline JavaScript
        return `<span style='color:#34b0cb !important;cursor:pointer' onclick="event.preventDefault(); var link = document.createElement('a'); link.href = '${url}'; link.download = ''; document.body.appendChild(link); link.click(); document.body.removeChild(link);">Click to download</span>`;
      } else {
        // For regular view links, just return a clickable anchor tag
        return `<a style='color:#34b0cb !important;cursor:pointer' href="${url}" target="_blank">Click to view the presentation</a>`;
      }
    });

    return response;
  }



  const fetchMessageResponse = async (data, loadingIndex = '') => {
    const { userId, response, messagetype, fileData, assistant } = data;
    if (!loadingIndex) {
      setUserAsTyping(data);

    }
    var msg = '';
    let msg_data = JSON.stringify({
      conversation_id: userId,
      profile_id: SSO?.id,
      user_name: SSO?.chat_username,
      prompt: response.content,
      file_ids: fileData?.map(item => item.id !== null && item.id !== undefined ? item.id : item.name),
      assistant_model_name: assistant.modal_name,
      assistant_id: assistant.assistant_id,
    });

    console.log('msg_datamsg_data', msg_data)
    try {
      const responses = await axios.post(
        `${BASE_URL}/chat/message`
        , {
          data: msg_data,
          defualtModel: assistant.modal_name,
          messagetype: messagetype,
          prompt: response.content,
          agency_id: SSO?.agency_id
        });
      if (responses) {
        msg = processLinks(responses?.data?.data?.data);
        setUserAsNotTyping(data);
        if (loadingIndex) {
          setLoadingStateslide((prev) => ({ ...prev, [loadingIndex]: false }));
          console.log(msg, "sdasdfasdfasdfasdfasdfsad")
        }
      }
    } catch (error) {
      console.log(error);
      await axios
        .get(BASE_URL + "/chat/delete?id=" + userId)
        .then(async function (response) {
          // window.location.href = `/`;
        })
        .catch(function (error) { });
    }
    let userIndex = users.findIndex((user) => user.id === userId);
    const usersCopy = JSON.parse(JSON.stringify(users));
    const newMsgObject = {
      content: msg,
      sender: userId,
      time: new Date().toLocaleTimeString(),
      status: null,
    };
    const payload = {
      message: newMsgObject,
      conversation_id: userId,
    };
    await axios
      .post(BASE_URL + "/message/response", { ...payload })
      .then(async function (response) { })
      .catch(function (error) { });
    var key = Object.keys(usersCopy[userIndex].messages);
    key = key[0];
    usersCopy[userIndex].messages[key].push(newMsgObject);
    setUsers(usersCopy);
  };

  const justToSaveMsg = async (msg, userId) => {
    let userIndex = users.findIndex((user) => user.id === userId);
    const usersCopy = JSON.parse(JSON.stringify(users));
    const newMsgObject = {
      content: msg,
      sender: userId,
      time: new Date().toLocaleTimeString(),
      status: null,
    };
    const payload = {
      message: newMsgObject,
      conversation_id: userId,
    };
    await axios
      .post(BASE_URL + "/message/response", { ...payload })
      .then(async function (response) { })
      .catch(function (error) { });
    if (usersCopy[userIndex]?.messages) {
      var key = Object.keys(usersCopy[userIndex]?.messages);
      key = key[0];
      usersCopy[userIndex].messages[key].push(newMsgObject);
      setUsers(usersCopy);
    }
  }
  const Chatoption = async (userId, message) => {
    await axios
      .get(BASE_URL + "/super_admin_settings")
      .then(async function (response) {

        fetchMessageResponse({
          userId: userId,
          response: { content: message },
          defualtModel: response.data.data.default_modal,
        });
      });

  }
  const refresh = (newdata) => {
    users.unshift(newdata);
    setUsers(users);
  };

  const onLoad = (SSO) => {
    if (SSO !== "" && SSO !== undefined) {
      getLocationUsers(SSO);
      getAgencyConfig(SSO);
      GetChats(SSO, 0, true);
      addYourAIinUser(SSO)
    }
  };
  useEffect(() => {
    onLoad(SSO);
  }, [SSO]);

  // useEffect(() => {
  //   if (data.length && !processing) {
  //     GetChats(SSO, 1, false);
  //   }
  // }, [data]);

  useEffect(() => {
    checkSSO();
  }, []);

  const setUserAsUnread = (userId) => {
    _updateUserProp(userId, "unread", 0);
  };

  const ChatSubmit = async (payload, fromPrompt = false) => {
    return await axios
      .post(BASE_URL + "/chat", {
        userId: { id: SSO?.userUUID, type: "author", username: SSO?.chat_username },
        ...payload,
      })
      .then(async function (response) {
        var chat_data = {};
        chat_data["Welcome " + response.data.data.username] = [];
        const chat = {
          id: response.data.data.conversation_id,
          locationId: response.data.data.locationId,
          userId: response.data.data.userId,
          name: "",
          whatsapp_name: response.data.data.username,
          messages: chat_data,
          welcome_array: "Welcome " + response.data.data.username,
          fromPrompt: fromPrompt,
        };
        return chat;
      })
      .catch(function (error) {
        return error;
      });
  };

  const addNewMessage = async (userId, message, prompt, type, selectedFiles, assistant, messagetype, fileType = '') => {
    const fileData = selectedFiles?.map(file => ({
      id: file.id,
      name: file.name
    }));
    let userIndex = users.findIndex((user) => user.id === userId);
    var usersCopy = [...users];

    const newMsgObject = {
      content: message,
      sender: null,
      time: new Date().toLocaleTimeString(),
      status: "delivered",
      // Store the file data in the new message object
      file_ids: fileData,
      file_type: fileType // KB | PNG
    };

    var key = Object.keys(usersCopy[userIndex].messages);
    key = key[0];

    if (type === "savetodb") {
      usersCopy[userIndex].messages[key].push(newMsgObject);
    }

    const payload = {
      message: newMsgObject,
      conversation_id: userId,
      type: type,
    };

    setUsers(usersCopy);

    await axios
      .post(BASE_URL + "/message", { ...payload })
      .then(async function (response) {
        if (response.data.chatupdated) {
          usersCopy[userIndex].name = message;
          setUsers(usersCopy);
        } else {
          setUsers(usersCopy);
        }
        if (prompt !== undefined) {
          fetchMessageResponse({
            userId: userId,
            response: { content: prompt },
            messagetype: messagetype,
            fileData: fileData,
            assistant: assistant
          });
        } else {
          fetchMessageResponse({
            userId: userId,
            response: { content: message },
            messagetype: messagetype,
            fileData: fileData,
            assistant: assistant

          });
        }
      })
      .catch(function (error) {
        if (error.response.data.errorType === 'balance') {
          showToast(error.response.data.message, 'err')
          setErrorType(true);
          setErrorMsg(error.response.data.message);
        }
      });
  };

  const contextuploadFilefrominput = async (files, chatId = '', setSpinnerLoading) => {
    // return 0
    const audioformData = new FormData();
    const formData = new FormData();
    const audiofilestype = [
      'audio/mpeg',  // mp3
      'video/mp4',   // mp4
      'video/mpeg',  // mpeg
      'audio/mp4',   // m4a
      'audio/wav'    // wav
    ];
    const audiofiles = [];
    const otherFiles = [];
    let audioTranscript = '';
    let fileError = false;

    for (let index = 0; index < files.length; index++) {
      const fileType = files[index].file?.type;

      if (audiofilestype.includes(fileType)) {
        audiofiles.push(files[index]);
      } else {
        otherFiles.push(files[index]);
      }
    }

    // Append allowed files to formData

    for (let file of audiofiles) {
      audioformData.append('file', file.file ? file.file : file);
      audioformData.append('translate', file.isChecked);
    }
    for (let file of otherFiles) {
      formData.append('file', file.file ? file.file : file);
    }
    audioformData.append('profile_id', SSO?.id)
    audioformData.append('username', SSO?.chat_username)
    audioformData.append('model_name', "openai/gpt-4o")
    formData.append('profile_id', SSO?.id)
    formData.append('username', SSO?.chat_username)
    formData.append('model_name', "openai/gpt-4o")

    if (audiofiles.length > 0) {
      try {
        const response = await axios.post(
          `${BASE_URL}/chat/multimedia`
          , audioformData, {
          'Content-Type': 'multipart/form-data', // Optional: axios sets this automatically

        });
        if (response) {
          return response;
        }
        audioTranscript = response
      } catch (error) {
        console.log(error);
        fileError = true;
      }


    }

    if (otherFiles.length > 0) {
      try {
        const response = await axios.post(
          `${BASE_URL}/chat/multimedia`
          , formData, {
          'Content-Type': 'multipart/form-data', // Optional: axios sets this automatically

        });
        if (response) {
          setSpinnerLoading(false)
          return response;
        }
        audioTranscript = response
      } catch (error) {
        setSpinnerLoading(false)
        console.log(error);
        fileError = true;
      }
    }
    if (fileError) {
      setSpinnerLoading(false)
      return {
        status: fileError,
        transcript: audioTranscript
      }
    } else {
      setSpinnerLoading(false)
      return {
        status: "uploaded",
        transcript: audioTranscript
      }
    }
  }

  const createMemory = async (profileId, content) => {
    let data = JSON.stringify({
      "content": content
    });
    try {
      const response = await axios.post(
        `${BASE_URL}/chat/assistants/memory`
        , {
          profile_id: profileId,
          data: data
        });
      if (response) {
        return response.data.data
      }
    } catch (error) {
      return error;
    }


  }

  const updateMemory = async (profileId, content) => {
    let data = JSON.stringify({
      "content": content
    });
    try {
      const response = await axios.put(
        `${BASE_URL}/chat/assistants/memory`
        , {
          profile_id: profileId,
          data: data
        });
      if (response) {
        return response.data.data
      }
    } catch (err) {
      return "error";
    }

  }

  const deleteMemory = async (profileId) => {
    try {
      const response = await axios.post(
        `${BASE_URL}/chat/assistants/memory/delete`
        , {
          profile_id: profileId,
        });
      if (response) {
        return response.data.data
      }
    } catch (error) {
      return error;
    }

  }

  const getMemory = async (profileId) => {
    try {
      const response = await axios.get(`${BASE_URL}/assistants/memory?profile_id=${profileId}`)
      if (response) {
        return response.data.data
      }
    } catch (error) {
      return error;
    }
  }

  return (
    <UsersContext.Provider
      value={{
        users,
        SSO,
        data,
        setUsers,
        locValid,
        setlocValid,
        setUserAsUnread,
        addNewMessage,
        contextuploadFilefrominput,
        processing,
        refresh,
        userTyping,
        hideLeftSidebar,
        showLeftSidebar,
        isActiveSide,
        handleDeleteContact,
        toast,
        showToast,
        hideToast,
        showExploreContent,
        enableExplore,
        enableChat,
        setOnFromPrompt,
        setOffFromPrompt,
        fromPromptMsg,
        Chatoption,
        getLocationUsers,
        locationUsers,
        handleRefreshData: GetChats,
        GetchatData,
        agencyConfig,
        agencyInfo,
        errorType,
        setErrorType,
        errorMsg,
        justToSaveMsg,
        ChatSubmit,
        getMemory,
        createMemory,
        updateMemory,
        deleteMemory,
        checkSSO,
        fetchMessageResponse,
        setLoadingStateslide,
        loadingStateslide
      }}
    >
      {children}
      {toast.isVisible && <Toast message={toast.message} styles={toastStyles} onClose={hideToast} />}
    </UsersContext.Provider>
  );
};

export { useUsersContext, UsersProvider };
