import React, { useContext, useEffect, useRef, useState, useCallback } from "react";
import "./styles/main.css";
import maybeIcon from "../../assets/images/maybe-icon.png";
import ShareChatModal from "components/ShareChatModal";
import ChatInput from "./components/ChatInput";
import Icon from "components/Icon";
import Convo from "./components/Convo";
import { useUsersContext } from "context/usersContext";
import { PromptsContext } from "context/promptsContext";
import MyDropzone from './components/MyDropzone'; // Import your Dropzone component
import ErrorHandlingModal from "components/ErrorHandlingModal";
import filesubnmitted from "./folder-access.ico"
import Searchsidebar from "pages/Chat/Sidebar/search";
import Tasksidebar from "pages/Chat/Sidebar/taskbar";
import "./styles/v3.css"
import EditChatModal from "components/EditChatModal";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import axios from "axios";
import env from "config";
import DeleteChatModal from "components/DeleteChatModal";
import Assistanbtsidebar from "./Sidebar/assistant";
import { format, isToday, isYesterday, subDays } from 'date-fns'; // Import date-fns for easier date handling

const BASE_URL = `${env.API_URL}/v1`;


const Chat = ({ match, history }) => {
  const { promptsData, getPrompts, favouritesData, getFavourites, chatCategories, getChatCategories } = useContext(PromptsContext);
  const { users: contacts, users, setUserAsUnread, SSO, addNewMessage, contextuploadFilefrominput, showLeftSidebar, hideLeftSidebar, isActiveSide, agencyInfo, errorType, setErrorType, errorMsg, ChatSubmit, showToast, refresh, handleDeleteContact, GetchatData, setOnFromPrompt, getLocationUsers, locationUsers, handleRefreshData } = useUsersContext();

  const [chats, setchats] = useState(contacts);
  const favChatIds = favouritesData?.map(fav => fav.chat_id);
  const favChats = chats?.filter(chat => favChatIds?.includes(chat.id));
  const userId = match.params.id;
  let user = users.filter((user) => user.id === userId)[0];
  const initalAssistant = {
    assistant_name: "Brand AI",
    modal_name: "openai/gpt-4o",
    assistant_id: SSO?.assistant_id,
    status: true
  };
  const lastMsgRef = useRef(null);
  const [showAttach, setShowAttach] = useState(false);
  const [showEmojis, setShowEmojis] = useState(false);
  const [showProfileSidebar, setShowProfileSidebar] = useState(false);
  const [showSearchSidebar, setShowSearchSidebar] = useState(false);
  const [newMessage, setNewMessage] = useState('');
  const [footerHeight, setFooterHeight] = useState('64px');
  const [goBottomBtn, setGoBottomBtn] = useState('120px');
  const [alertModel, setAlertModel] = useState(false);
  const [successModel, setSuccessModel] = useState(false);
  const [saveModel, setsaveModel] = useState(false);

  const [modelContent, setModelContent] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(errorType);

  const [isOpenAddPromptModal, setIsAddPromptModal] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [fileType, setFileType] = useState('');
  const [copySelectedFiles, setCopySelectedFiles] = useState([]);
  const [threeDotOptions, setThreeDotOptions] = useState({});
  const [threeDot1Options, setThreeDot1Options] = useState({});
  const [threeDot2Options, setThreeDot2Options] = useState({});
  const [selectedAssistantId, setSelectedAssistantId] = useState([]);
  const [assistantModel, setassistantModel] = useState(false);
  const [uploadonkbfiles, setuploadonkbfiles] = useState({})
  const [asistantSelected, setasistantSelected] = useState(initalAssistant)
  const [askAssistant, setAskAssistant] = useState(false);

  const [assistantData, setAssistantData] = useState([initalAssistant]);
  const logoSrc = agencyInfo?.Customization?.chatapp_logo || maybeIcon;

  const [showScrollButton, setShowScrollButton] = useState(false);
  const [loader, setLoader] = useState(false);
  const [openSidebar, setOpenSidebar] = useState(null);
  const today = new Date();
  const yesterday = subDays(today, 1); // Yesterday's date
  const last7Days = subDays(today, 7); // 7 days ago

  // Filter chats by categories
  const chatsByToday = chats.filter(item => isToday(new Date(item.createdAt)));
  const chatsByYesterday = chats.filter(item => isYesterday(new Date(item.createdAt)));

  // Combine chats that are within the last 7 days and older chats (remaining)
  const chatsByLast7DaysAndOlder = chats.filter(item => {
    const createdAtDate = new Date(item.createdAt);
    return createdAtDate <= last7Days || (createdAtDate < today && !isYesterday(createdAtDate) && !isToday(createdAtDate));
  });
  // Function to handle sidebar toggling
  const toggleSidebar = (sidebar) => {
    // If the same sidebar is clicked, close it
    if (openSidebar === sidebar) {
      closeAllSidebars(); // Close all sidebars
      setOpenSidebar(null); // No sidebar is open
    }
    // If a different sidebar is clicked, close the current one and open the new one
    else {
      closeAllSidebars(); // Close all sidebars
      openSelectedSidebar(sidebar); // Open the selected sidebar
      setOpenSidebar(sidebar); // Set the selected sidebar as open
    }
  };

  // Function to close all sidebars
  const closeAllSidebars = () => {
    document.getElementById("tasksidebar").style.width = "0";
    document.getElementById("searchsidebar").style.width = "0";
    document.getElementById("assistantsidebar").style.width = "0";
  };

  // Function to open a specific sidebar
  const openSelectedSidebar = (sidebar) => {
    if (sidebar === 'search') {
      document.getElementById("searchsidebar").style.width = "380px";
    } else if (sidebar === 'task') {
      document.getElementById("tasksidebar").style.width = "380px";
    } else if (sidebar === 'assistant') {
      document.getElementById("assistantsidebar").style.width = "380px";
    }
  };


  const chatContentRef = useRef(null);

  const handleFileSelect = (files) => {
    setSelectedFiles(files);
    setCopySelectedFiles(files);
  };

  const Assistant = async () => {
    axios.get(`${BASE_URL}/assistant/fetchbyuser?user_id=${SSO.userUUID}&location_id=${SSO.id}`).then((response) => {
      let filteredResponse = response.data.data.filter(item => item.status && item.ui_enable);
      const match = filteredResponse.find(item => item.assistant_id === SSO.default_assistant);
      let rearrangedResponse = [];
      if (match) {
        rearrangedResponse = [
          { ...match, selected: true },
          initalAssistant,
          ...filteredResponse
            .filter(item => item.assistant_id !== SSO.default_assistant)
            .map(item => ({ ...item, selected: false })),
        ];
      } else {
        rearrangedResponse = [
          initalAssistant,
          ...filteredResponse.map(item => ({ ...item, selected: false })),
        ];
      }

      if (response.data?.crm_agent) {
        
        rearrangedResponse.push({
          assistant_name: "CRM Agent",
          modal_name: "openai/gpt-4o",
          assistant_id: response.data?.crm_agent,
          status: true
        })
       
      } else {
        setasistantSelected(rearrangedResponse[0]);
        console.log("ffff")
      }
      setAssistantData(rearrangedResponse);
    }).catch((error) => {
      let updatedResponse = [initalAssistant];
      let filteredResponse = updatedResponse.filter(item => item.status);
      setAssistantData(filteredResponse);
    })
  }


  const updateLocaStorageForAssistant = (item) => {
    localStorage.setItem("assistant_id", item.assistant_id)
    localStorage.setItem("assistant_name", item.assistant_name)
  }

  const AssistantUpdate = (item) => {
    updateLocaStorageForAssistant(item);
    setasistantSelected(item);
  }

  const changeassistant = (item) => {
    setasistantSelected(item);
    updateLocaStorageForAssistant(item)
    justCloseIt();
    handleSubmit(item);
    setAskAssistant(false)
  }

  useEffect(() => {
    Assistant()
  }, [assistantModel])

  useEffect(() => {
    if (!user) {
      const redirectUrl = `/`;
      history.push(redirectUrl);
    } else {
      scrollToLastMsg();
      setUserAsUnread(user.id);
    }
  }, []);

  useEffect(() => {
    setIsModalOpen(errorType)
  }, [errorType])

  useEffect(() => {
    if (user) {
      scrollToLastMsg();
    }
  }, [users, user]);

  const justCloseIt = () => {
    setErrorType(false);
    setIsAddPromptModal(false);
    setEditModel(false)
    setThreeDotOptions({});
    setThreeDot1Options({});
    setThreeDot2Options({});
    setIsOpendrop(false)
    setAskToDeleteModal(false);
    setassistantModel(false);
    setIsOpendrop(false)
    setAskToShareModal(false)
    document.getElementById("searchsidebar").style.width = "0";
    document.getElementById("tasksidebar").style.width = "0";
    document.getElementById("assistantsidebar").style.width = "0";
    setLoader(false)

  }

  useEffect(() => {
  }, [isActiveSide])

  useEffect(() => {
    if (footerHeight) {
      const newHeight = parseInt(footerHeight.replace(/\D/g, '')) + 56 + 'px';
      setGoBottomBtn(newHeight)
    }
  }, [footerHeight])

  const closeSidebar = () => {
    setShowSearchSidebar(false)
    showLeftSidebar();
  }

  const scrollToLastMsg = () => {
    if (lastMsgRef.current) {
      // alert("diyh");
      lastMsgRef.current.scrollIntoView();
    }
  };
  const LoadTaskonchat = async (user_msg, prompt) => {
    setOnFromPrompt(SSO?.userUUID);
    const payload = {
      locationId: SSO?.id,
      username: SSO?.chat_username,
      assistant_id: asistantSelected?.assistant_id,
      assistant_name: asistantSelected?.assistant_name
    };
    const temp = await ChatSubmit(payload, true);
    if (!temp?.response) {
      refresh(temp);
      const chatUrl = `/chat/${temp.id}`;
      setUserAsUnread(temp.id);
      const assistant = asistantSelected.modal_name !== "" ? asistantSelected : "openai/gpt-4o";
      addNewMessage(temp.id, user_msg, prompt, "savetodb", selectedFiles, assistant, "notimage");
      history.push(chatUrl);
      // setTimeout(() => {
      //   Refershchat();
      // }, 2000);
    } else {
      setNewMessage("");
      setTimeout(() => {
        document.getElementById('gotovie')?.scrollIntoView({ behavior: 'smooth' });
      }, 2000);
    }
  };
  const CreateNewChat = async () => {
    const payload = {
      locationId: SSO?.id,
      username: SSO?.chat_username,
      assistant_id: asistantSelected?.assistant_id,
      assistant_name: asistantSelected?.assistant_name

    };
    const temp = await ChatSubmit(payload);


    if (!temp?.response) {
      refresh(temp);
      const chatUrl = `/chat/${temp.id}`;
      setUserAsUnread(temp.id);
      const assistant = asistantSelected.modal_name !== "" ? asistantSelected : "openai/gpt-4o";
      addNewMessage(temp.id, newMessage, newMessage, "savetodb", selectedFiles, assistant, "notimage");
      history.push(chatUrl);
      setTimeout(() => {
        Refershchat();
      }, 2000);
    } else {
      showToast('Something went wrong!');
    }
    setNewMessage("");
    setTimeout(() => {
      document.getElementById('gotovie')?.scrollIntoView({ behavior: 'smooth' });
    }, 2000);
  }

  const submitNewMessage = (prompt = newMessage, type = "savetodb", messagetype = "notimage") => {
    setSelectedFiles([]);
    scrollToLastMsg();
    const assistant = asistantSelected.modal_name !== "" ? asistantSelected : "openai/gpt-4o";
    addNewMessage(user.id, newMessage, prompt, type, selectedFiles, assistant, messagetype, fileType);
    setNewMessage("");
    scrollToLastMsg();
    setFileType('')
    // setTimeout(() => {
    //   Refershchat();
    // }, 2000);
    setTimeout(() => {
      document.getElementById('gotovie')?.scrollIntoView({ behavior: 'smooth' });
    }, 2000);
  };

  const gettingfilefromdropzone = async (files) => {
    var response = await contextuploadFilefrominput(user.id, files);
    if (response === "uploaded") {
      setModelContent("file Submitted ..!")
      setAlertModel(false);
      setSuccessModel(true)
    } else {
    }
  }

  const uploadFilefrominput = async (stateget) => {
    setAlertModel(stateget);
  }

  const toggleThreeDotOption = (index) => {
    setThreeDotOptions((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };
  const toggleThree1DotOption = (index) => {
    setThreeDot1Options((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };
  const toggleThree2DotOption = (index) => {
    setThreeDot2Options((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  const [isOpen, setIsOpen] = useState(false);

  const openNav = () => {
    setIsOpen(true);
    const removeElement = document.getElementById("outsidebar");
    if (removeElement) {
      removeElement.style.display = "none";
    }
  };

  const closeNav = () => {
    setIsOpen(false);
    const removeElement = document.getElementById("outsidebar");
    if (removeElement) {
      removeElement.style.display = "block";
    }
  };
  const [isOpendrop, setIsOpendrop] = useState(false);

  const toggleDropdownd = () => setIsOpendrop(!isOpendrop);
  const handleItemClick = (item) => {
    setIsOpendrop(false);
    setTimeout(() => {
      const element = document.getElementById("appendsavelocation");
      if (element) {
        element.innerHTML = item.business_name;
      }
    }, 1000);
  };

  const handleSubmit = async (item = []) => {

    const payload = {
      locationId: SSO?.id,
      username: SSO?.chat_username,
      assistant_id: item ? item.assistant_id : asistantSelected?.assistant_id,
      assistant_name: item ? item.assistant_name : asistantSelected?.assistant_name
    };

    const temp = await ChatSubmit(payload);

    if (!temp?.response) {
      refresh(temp);

      const chatUrl = `/chat/${temp.id}`;
      setUserAsUnread(temp.id);
      history.push(chatUrl);
      setTimeout(() => {
        Refershchat();
      }, 2000);
    } else {
      showToast("Something went wrong!");
    }
  };

  const OpenAssistant = () => {
    document.getElementById("tasksidebar").style.width = "0";
    document.getElementById("searchsidebar").style.width = "0";
    if (isOpendrop === false) {
      setIsOpendrop(true);
    } else {
      setIsOpendrop(false);
    }
  }

  const [askToDeleteModal, setAskToDeleteModal] = useState(false);
  const askToDelete = async (e, chat) => {
    e.stopPropagation();
    e.preventDefault();
    setSelectedContact(chat);
    setAskToDeleteModal(true);
  }
  const handleDeleteClick = () => {
    handleDeleteContact(selectedContact.id);
    justCloseIt();
    setTimeout(() => {
      Refershchat();
    }, 2000);
  };

  const [EditModel, setEditModel] = useState(false)
  const [selectedContact, setSelectedContact] = useState();

  const handleeditchat = (chat) => {
    console.log("Chat", chat)
    setEditModel(true);
    setSelectedContact(chat)
  }
  const Refershchat = async () => {
    try {
      const chatupdated = await GetchatData()
      console.log("Updated Chat", chatupdated)
      setchats(chatupdated)
      await getFavourites();
      await handleRefreshData()
    } catch (error) {
      console.error("Error refreshing chat and fetching favourites:", error);
    }

  };
  const handleFavourite = async (e, chat) => {
    e.stopPropagation();
    e.preventDefault();
    const response = await axios.get(`${BASE_URL}/chat/add/favourite?chat_id=${chat?.id}&user_id=${SSO?.userUUID}&location_id=${SSO?.id}`);
    console.log("Add Favourit", response)
    if (response) {
      await Refershchat();
      justCloseIt()
    }
  }

  const removeFromFav = async (e, chat) => {
    e.stopPropagation();
    e.preventDefault();
    const favoriteId = favouritesData.find(favorite => favorite.chat_id === chat?.id)?._id;
    const response = await axios.get(`${BASE_URL}/chat/remove/favourite?chatId=${favoriteId}&user_id=${SSO?.userUUID}&location_id=${SSO?.id}`);
    if (response) {
      Refershchat();
      justCloseIt()

    }
  }
  const UploadOnKB = async (file) => {
    setassistantModel(true);
    setuploadonkbfiles(file);
  }
  const uploadonKBSendTobackend = async (e) => {
    e.preventDefault();
    setLoader(true);
    const payload = {
      username: SSO?.chat_username,
      profile_id: SSO?.id,
      assistant_ids: selectedAssistantId,
      file_id: uploadonkbfiles.name,
    }
    const response = await axios.post(
      `${BASE_URL}/chat/uploadonkb`
      , payload, {
      'Content-Type': 'multipart/form-data',
    });
    if (response) {
      Refershchat();
      setSelectedAssistantId([]);
      justCloseIt()
      setsaveModel(true);
    }
  }

  const threeDotRef = useRef(null);
  const threeDot1Ref = useRef(null);
  const threeDot2Ref = useRef(null);
  const AskAssistantRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (AskAssistantRef.current && !AskAssistantRef.current.contains(event.target)) {
        setAskAssistant(false)
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [AskAssistantRef]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (threeDotRef.current && !threeDotRef.current.contains(event.target)) {
        setThreeDotOptions({});
      }
      if (threeDot1Ref.current && !threeDot1Ref.current.contains(event.target)) {
        setThreeDot1Options({});
      }
      if (threeDot2Ref.current && !threeDot2Ref.current.contains(event.target)) {
        setThreeDot2Options({});
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [threeDotRef, threeDot1Ref, threeDot2Ref]);

  const AssistantDropdown = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (AssistantDropdown.current && !AssistantDropdown.current.contains(event.target)) {
        setIsOpendrop(false)
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [AssistantDropdown]);



  const handleFileRemove = (index) => {
    setSelectedFiles(prevFiles => prevFiles.filter((_, i) => i !== index));
  };

  const handleAssistantSelect = (item) => {
    if (selectedAssistantId.includes(item.assistant_id)) {
      setSelectedAssistantId((prevItems) => prevItems.filter((id) => id !== item.assistant_id));
    } else {
      setSelectedAssistantId((prevItems) => [...prevItems, item.assistant_id]);
    }
  };


  const scrollToBottom = () => {
    scrollToLastMsg();
  };

  const handleScroll = useCallback(() => {
    if (chatContentRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = chatContentRef.current;
      const isAtBottom = scrollHeight - scrollTop === clientHeight;
      setShowScrollButton(!isAtBottom);
    }
  }, []);

  useEffect(() => {
    const chatContent = chatContentRef.current;
    if (chatContent) {
      chatContent.addEventListener('scroll', handleScroll);
      return () => chatContent.removeEventListener('scroll', handleScroll);
    }
  }, [handleScroll]);


  const updateAssistant = (item) => {
    changeassistant(item);
  }

  const [askToShareModal, setAskToShareModal] = useState(false);
  const askToShare = async (e, chat) => {
    e.stopPropagation();
    e.preventDefault();
    setSelectedContact(chat);
    setAskToShareModal(true);
  }



  return (
    <>
      <EditChatModal
        isModalOpen={EditModel}
        edit_data={selectedContact}
        justCloseIt={justCloseIt}
        refreshChat={Refershchat}
      />
      <DeleteChatModal
        modelType="Chat"
        isModalOpen={askToDeleteModal}
        contact={selectedContact}
        yesDelete={handleDeleteClick}
        justCloseIt={justCloseIt}
        type="prompt"
      />
      <ShareChatModal
        isModalOpen={askToShareModal}
        contact={selectedContact}
        justCloseIt={justCloseIt}
        getLocationUsers={getLocationUsers}
        locationUsers={locationUsers}
        handleRefreshData={handleRefreshData}
      />
      <div className={`v_3sidebar ${isOpen ? "v_3sidebar--open" : ""}`}>

        <div className="v3setonsidebaricon" >
          <div
            style={{ cursor: "pointer" }}
            aria-label="Toggle sidebar"
            className="scrollchat"
            onClick={closeNav}
          >
            <Icon id="tooglesidebar" />
          </div>
          <div onClick={() => handleSubmit(asistantSelected)}
            style={{ position: "relative", cursor: "pointer", margin: "0px 20px" }}>
            <div class="v3_chatnew_hover2-text">
              <Icon id="newchatIcon" />

              <span class="v3_chatnew_tooltip2-text" >New Chat</span>

            </div>
          </div>
        </div>
        <div className="v3_list_main">


          <Searchsidebar text={
            <div className="v3sidebarlist">
              <Icon id="v3_search" />
              <div
                style={{ cursor: "pointer", margin: "0px 20px" }}
                aria-label="New chat"
              >
                Search
              </div>
            </div>
          }
            toggleSidebar={toggleSidebar}
            updateAssistant={updateAssistant}
            assistantData={assistantData}
            LoadTaskonchat={LoadTaskonchat}
          />
          <Assistanbtsidebar text={
            <div className="v3sidebarlist" >
              <Icon id="aichatIcon" />
              <div
                style={{ cursor: "pointer", margin: "0px 20px" }}
                aria-label="New chat"
              >
                Your AI Agents
              </div>
            </div>
          }
            toggleSidebar={toggleSidebar}
            LoadTaskonchat={LoadTaskonchat}
            OpenAssistant={OpenAssistant}
            assistantData={assistantData}
            changeassistant={changeassistant}
            asistantSelected={asistantSelected}
          />

          <Tasksidebar text={
            <div className="v3sidebarlist">
              <Icon id="v3_4dots" />
              <div
                style={{ cursor: "pointer", margin: "0px 20px" }}
                aria-label="New chat"
              >
                Task
              </div>
            </div>
          }
            toggleSidebar={toggleSidebar}
            assistantData={assistantData}
            LoadTaskonchat={LoadTaskonchat}
            asistantSelected={asistantSelected}
          />

          {favChats.map((item, index) => (
            <>
              <Link
                onClick={() => { setUserAsUnread(item.id); AssistantUpdate(item) }}
                to={`/chat/${item.id}`}
              >
                <div className="v3_chatlisr" style={{ position: "relative" }}>
                  <div
                    style={{ cursor: "pointer", margin: "0px 20px", width: "90%", }}
                    aria-label="New chat"
                    className="v3_chatlist_title"

                  >
                    {item.name}
                  </div>

                  <div style={{ width: "10%" }}
                    onClick={(e) => removeFromFav(e, item)}
                  >
                    <Icon id="pintask" />
                  </div>


                </div>
              </Link>
            </>
          ))}


          <div ref={threeDotRef}>
            <div className="dateWiseWrap">
              <h5 className="datewise">Today</h5>
            </div>
            {chatsByToday
              .filter(item => item.userId.some(user => user.id === SSO?.userUUID && user.type === "author"))
              .filter(item => !favChats.some(fav => fav.id === item.id))
              .map((item, index) => (
                <Link

                  onClick={() => { setUserAsUnread(item.id); AssistantUpdate(item) }}
                  to={`/chat/${item.id}`}
                >
                  <div className="v3_chatlisr" style={{ position: "relative" }}>
                    <div
                      style={{ cursor: "pointer", margin: "0px 20px", width: "90%", }}
                      aria-label="New chat"
                      className="v3_chatlist_title"

                    >
                      {item.name}
                    </div>

                    <div style={{ width: "10%" }}
                      onClick={() => toggleThreeDotOption(index)}
                    >
                      <Icon id="threeDots" />
                    </div>

                    {threeDotOptions[index] && (
                      <div className="v3_three_dots_options_sidebar">
                        <div className="v3_three_dots_options_box" onClick={(e) => handleFavourite(e, item)} >
                          <div className="v3_three_dots_options_icon">
                            <Icon id="pintask" />
                          </div>
                          <p className="v3_three_dots_options_text">Pin</p>

                        </div>

                        <div className="v3_three_dots_options_box" onClick={() => handleeditchat(item)} >
                          <div className="v3_three_dots_options_icon">
                            <Icon id="pencilblack" />
                          </div>
                          <p className="v3_three_dots_options_text">Rename</p>
                        </div>

                        <div className="v3_three_dots_options_box" onClick={(e) => askToShare(e, item)} >
                          <div className="v3_three_dots_options_icon">
                            <Icon id="share" />
                          </div>
                          <p className="v3_three_dots_options_text">Share</p>
                        </div>

                        {/* <div className="v3_three_dots_options_box">
                          <div className="v3_hover-text" style={{ display: "flex", justifyContent: "space-between" }}>
                            <div className="v3_three_dots_options_icon">
                              <Icon id="move" />
                            </div>
                            <p className="v3_three_dots_options_text" >Move</p>

                          </div>
                        </div> */}

                        <div className="v3_three_dots_options_box" onClick={(e) => askToDelete(e, item)} >
                          <div className="v3_three_dots_options_icon">
                            <Icon id="delete" />
                          </div>
                          <p className="v3_three_dots_options_text" >Delete</p>
                        </div>
                      </div>
                    )
                    }
                  </div>
                </Link>
              ))}
          </div>
          <div ref={threeDot1Ref}>
            <div className="dateWiseWrap">
              <h5 className="datewise">Yesterday</h5>
            </div>
            {chatsByYesterday
              .filter(item => item.userId.some(user => user.id === SSO?.userUUID && user.type === "author"))
              .filter(item => !favChats.some(fav => fav.id === item.id))
              .map((item, index) => (
                <Link

                  onClick={() => { setUserAsUnread(item.id); AssistantUpdate(item) }}
                  to={`/chat/${item.id}`}
                >
                  <div className="v3_chatlisr" style={{ position: "relative" }}>
                    <div
                      style={{ cursor: "pointer", margin: "0px 20px", width: "90%", }}
                      aria-label="New chat"
                      className="v3_chatlist_title"

                    >
                      {item.name}
                    </div>

                    <div style={{ width: "10%" }}
                      onClick={() => toggleThree1DotOption(index)}
                    >
                      <Icon id="threeDots" />
                    </div>

                    {
                      threeDot1Options[index] && (
                        <div className="v3_three_dots_options_sidebar">
                          <div className="v3_three_dots_options_box" onClick={(e) => handleFavourite(e, item)} >
                            <div className="v3_three_dots_options_icon">
                              <Icon id="pintask" />
                            </div>
                            <p className="v3_three_dots_options_text">Pin</p>

                          </div>

                          <div className="v3_three_dots_options_box" onClick={() => handleeditchat(item)} >
                            <div className="v3_three_dots_options_icon">
                              <Icon id="pencilblack" />
                            </div>
                            <p className="v3_three_dots_options_text">Rename</p>
                          </div>

                          <div className="v3_three_dots_options_box" onClick={(e) => askToShare(e, item)} >
                            <div className="v3_three_dots_options_icon">
                              <Icon id="share" />
                            </div>
                            <p className="v3_three_dots_options_text">Share</p>
                          </div>

                          <div className="v3_three_dots_options_box" onClick={(e) => askToDelete(e, item)} >
                            <div className="v3_three_dots_options_icon">
                              <Icon id="delete" />
                            </div>
                            <p className="v3_three_dots_options_text" >Delete</p>
                          </div>
                        </div>
                      )
                    }
                  </div >
                </Link >
              ))}
          </div>
          <div ref={threeDot2Ref}>
            <div className="dateWiseWrap">
              <h5 className="datewise">Previous 7 Days</h5>
            </div>
            {chatsByLast7DaysAndOlder
              .filter(item => item.userId.some(user => user.id === SSO?.userUUID && user.type === "author"))
              .filter(item => !favChats.some(fav => fav.id === item.id))
              .map((item, index) => (
                <Link

                  onClick={() => { setUserAsUnread(item.id); AssistantUpdate(item) }}
                  to={`/chat/${item.id}`}
                >
                  <div className="v3_chatlisr" style={{ position: "relative" }}>
                    <div
                      style={{ cursor: "pointer", margin: "0px 20px", width: "90%", }}
                      aria-label="New chat"
                      className="v3_chatlist_title"

                    >
                      {item.name}
                    </div>

                    <div style={{ width: "10%" }}
                      onClick={() => toggleThree2DotOption(index)}
                    >
                      <Icon id="threeDots" />
                    </div>

                    {
                      threeDot2Options[index] && (
                        <div className="v3_three_dots_options_sidebar">
                          <div className="v3_three_dots_options_box" onClick={(e) => handleFavourite(e, item)} >
                            <div className="v3_three_dots_options_icon">
                              <Icon id="pintask" />
                            </div>
                            <p className="v3_three_dots_options_text">Pin</p>

                          </div>

                          <div className="v3_three_dots_options_box" onClick={() => handleeditchat(item)} >
                            <div className="v3_three_dots_options_icon">
                              <Icon id="pencilblack" />
                            </div>
                            <p className="v3_three_dots_options_text">Rename</p>
                          </div>

                          <div className="v3_three_dots_options_box" onClick={(e) => askToShare(e, item)} >
                            <div className="v3_three_dots_options_icon">
                              <Icon id="share" />
                            </div>
                            <p className="v3_three_dots_options_text">Share</p>
                          </div>

                          {/* <div className="v3_three_dots_options_box">
                          <div className="v3_hover-text" style={{ display: "flex", justifyContent: "space-between" }}>
                            <div className="v3_three_dots_options_icon">
                              <Icon id="move" />
                            </div>
                            <p className="v3_three_dots_options_text" >Move</p>

                          </div>
                        </div> */}

                          <div className="v3_three_dots_options_box" onClick={(e) => askToDelete(e, item)} >
                            <div className="v3_three_dots_options_icon">
                              <Icon id="delete" />
                            </div>
                            <p className="v3_three_dots_options_text" >Delete</p>
                          </div>
                        </div>
                      )
                    }
                  </div >
                </Link >
              ))}
          </div >


        </div >

      </div >
      <div id="v_3main" className={isOpen ? "v_3main--shift" : ""}>
        <div id="outsidebar">
          <div className="v3setsidebaricon">

            <div
              style={{ cursor: "pointer" }}
              aria-label="Toggle sidebar"
              className="scrollchat"
              onClick={openNav} // Add onClick event to toggle the sidebar
            >
              <Icon id="tooglesidebar" />
            </div>
            <div onClick={() => handleSubmit(asistantSelected)}
              style={{ position: "relative", cursor: "pointer", margin: "0px 20px" }}>
              <div class="v3_chatnew_hover-text">
                <Icon id="newchatIcon" />

                <span class="v3_chatnew_tooltip-text" >New Chat</span>

              </div>
            </div>

          </div>
        </div>
        <div className="chat__body" >
          <div className="v3_chat_bg" >
            <div ref={AssistantDropdown} style={{ zIndex: +9999 }}>
              <div style={{ width: '80%', position: 'fixed', marginRight: '30px', zIndex: +9999 }}>
                <div
                  className="v3_dropdowninchat"
                  onClick={toggleDropdownd}
                >
                  <span
                    style={{
                      width: "85%",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                      textAlign: "left",
                    }}
                    id="appendsavelocation"
                  >
                    {asistantSelected.assistant_name !== "" ? asistantSelected.assistant_name : "Brand AI"}
                  </span>
                  <span
                    className="v3_togglebtn"
                    style={{
                      transform: isOpendrop ? 'rotate(135deg)' : 'rotate(-45deg)',
                    }}
                  ></span>
                </div>
                {isOpendrop && (
                  <div className="v3_menuItems">
                    <div
                      style={{
                        padding: '10px',
                        cursor: 'pointer',
                        textAlign: "left",

                      }}
                    >
                      {assistantData?.map((item, index) => (
                        <div key={index} className="v3_dropdown_optionvalue" onClick={() => changeassistant(item)}>
                          <div style={{ margin: "5px 10px", cursor: "pointer" }}>
                            {SSO?.default_assistant === assistantData.assistant_id ? <label style={{ margin: "0", cursor: "pointer" }}>
                              <b>{item?.assistant_name}</b>
                            </label> :
                              <label style={{ margin: "0", cursor: "pointer" }}>
                                <b>{item?.assistant_name}</b>
                              </label>}
                          </div>
                        </div>
                      ))}
                    </div>

                  </div>
                )}
              </div>
            </div>


            <div
              className="v3chat__content"
              ref={chatContentRef}
              style={{ width: "100%", minHeight: "60vh", maxHeight: "80vh", padding: "0px 100px 10px 63px", marginTop: "50px" }}
            >
              <div className="v3chat__inner-content">
                {user ? (
                  <Convo
                    userId={userId}
                    lastMsgRef={lastMsgRef}
                    messages={user.messages}
                    newMessage={newMessage}
                    setNewMessage={setNewMessage}
                    submitNewMessage={submitNewMessage}
                    agencyInfo={agencyInfo}
                    copySelectedFiles={copySelectedFiles}
                    selectedFiles={selectedFiles}
                    UploadOnKB={UploadOnKB}
                    asistantSelected={asistantSelected}
                    assistantData={assistantData}
                  />
                ) : <>
                  <div className="v3iconBox">
                    {/* <img src={agencyInfo?.Customization ? agencyInfo?.Customization?.chatapp_logo : maybeIcon} alt="maybe icon" style={{ height: '100px' }} /> */}
                    <img src={logoSrc} alt="maybe icon" style={{ height: '100px' }} />
                    <p>
                      <b style={{ color: "black" }}>
                        {agencyInfo?.Customization ? agencyInfo?.Customization?.chatapp_text : 'How can Brand AI help you today?'}
                      </b>
                    </p>
                  </div>
                </>}
              </div>

              {showScrollButton && (
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                  <div
                    className="down_arrow_icon"
                    style={{ position: "fixed", bottom: "170px", zIndex: +9999, cursor: "pointer" }}
                    onClick={scrollToBottom}
                  >
                    <Icon id="chatDownArrow" />
                  </div>
                </div>
              )}
            </div>


            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "220px", position: "relative" }}>
              <footer className="v3chat__footer" style={{ zIndex: +9999 }}>
                {/* <button
                style={{ bottom: goBottomBtn }}
                className="chat__scroll-btn"
                aria-label="scroll down"
                onClick={scrollToLastMsg}
                id="trigerafterreguest"
              >
                <Icon id="downArrow" />
              </button> */}


                {selectedFiles?.map((file, index) => {
                  // Extract the part of the filename after the last '/'
                  const fileName = file.name.split('/').pop(); // This will get the last segment after '/'
                  return (
                    <div
                      key={index}
                      className="file_upload_input position-relative"
                      style={index === 0 ? { borderRadius: "22px 22px 0px 0px" } : {}}
                    >
                      <span style={{ position: "absolute", top: "5px", right: "10px", cursor: "pointer" }} onClick={() => handleFileRemove(index)}>&#10005;</span>
                      <span><Icon id="vectorfilev3" /></span>
                      <p style={{ padding: "15px", margin: "0px" }}>{fileName}</p>
                    </div>
                  );
                })}

                {askAssistant && <div
                  style={{
                    background: "white",
                    width: "250px",
                    borderRadius: "6px",
                    marginBottom: "10px",
                    border: "1px solid #F0F0F0",
                    position: "absolute",
                    bottom: "150px"
                  }}
                  ref={AskAssistantRef}
                >
                  {assistantData?.map((item, index) => (
                    <div key={index} className="v3_dropdown_optionvalue" onClick={() => changeassistant(item)}>
                      <div style={{ width: "100%", padding: "10px 10px", cursor: "pointer", borderBottom: "1px solid #F0F0F0" }}>
                        <label style={{ margin: "0", cursor: "pointer" }}>
                          <b>{item?.assistant_name}</b>
                        </label>
                      </div>
                    </div>
                  ))}
                </div>
                }

                <div
                  className="v3chat__input"
                  style={{
                    borderRadius: selectedFiles.length > 0 ? '0px 0px 0px 0px' : '22px 22px 0px 0px',
                    margin: "auto",
                    width: '90%',
                    background: "white",
                    paddingLeft: "20px"
                  }}
                >
                  Ask {asistantSelected?.assistant_name}
                </div>

                <ChatInput
                  showEmojis={showEmojis}
                  setShowEmojis={setShowEmojis}
                  showAttach={showAttach}
                  setShowAttach={setShowAttach}
                  newMessage={newMessage}
                  setNewMessage={setNewMessage}
                  submitNewMessage={userId !== undefined ? submitNewMessage : CreateNewChat}
                  setFooterHeight={setFooterHeight}
                  footerHeight={footerHeight}
                  agencyInfo={agencyInfo}
                  chatId={userId}
                  onFileSelect={handleFileSelect}
                  selectedFiles={selectedFiles}
                  updatestateodfiles={setSelectedFiles}
                  setSelectedFiles={setSelectedFiles}
                  setFileType={setFileType}
                  asistantSelected={asistantSelected}
                  setAskAssistant={setAskAssistant}
                  askAssistant={askAssistant}
                />

                <p className="text-center text-gray" style={{ marginLeft: "73px" }}>Your data is not used to train our models. AI can make mistakes please check your answer.</p>
              </footer>
            </div>


            {alertModel && <>
              <div className="modal-zz" onClick={() => setAlertModel(false)}>
                <div className="modal-content-zz" onClick={(e) => e.stopPropagation()}>
                  <p
                    style={{
                      position: "absolute",
                      right: 5,
                      width: "12px",
                      top: "10px",
                      height: "12px",
                      cursor: "pointer",
                    }}
                    onClick={() => setAlertModel(false)}
                  >
                  </p>

                  <MyDropzone gettingfilefromdropzone={gettingfilefromdropzone} />
                </div>
              </div>
            </>}
            {successModel && <>
              <div className="modal-zz" onClick={() => setSuccessModel(false)}>
                <div className="modal-content-zz" onClick={(e) => e.stopPropagation()}>
                  <p
                    style={{
                      position: "absolute",
                      right: 5,
                      width: "12px",
                      top: "10px",
                      height: "12px",
                      cursor: "pointer",
                    }}
                    onClick={() => setSuccessModel(false)}
                  >
                    X
                  </p>
                  <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <img src={filesubnmitted} width={"200px"} height={"200px"} />

                    <h1 className="text-center">Files are Submitted!</h1>

                  </div>
                </div>
              </div>
            </>}
            {saveModel && <>
              <div className="modal-zz" onClick={() => setsaveModel(false)}>
                <div className="modal-content-zz" onClick={(e) => e.stopPropagation()}>
                  <p
                    style={{
                      position: "absolute",
                      right: 5,
                      width: "12px",
                      top: "10px",
                      height: "12px",
                      cursor: "pointer",
                    }}
                    onClick={() => setsaveModel(false)}
                  >
                    X
                  </p>
                  <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <img src={filesubnmitted} width={"200px"} height={"200px"} />

                    <h1 className="text-center">File added in the assistant</h1>

                  </div>
                </div>
              </div>
            </>}
            {assistantModel && <>
              <div className="modal-zz" onClick={() => setassistantModel(false)}>
                <div className="modal-content-zz" onClick={(e) => e.stopPropagation()}>
                  <p
                    style={{
                      position: "absolute",
                      right: 5,
                      width: "12px",
                      top: "10px",
                      height: "12px",
                      cursor: "pointer",
                    }}
                    onClick={() => setassistantModel(false)}
                  >
                    X
                  </p>
                  <div >
                    <b className="text_black">Select Assistant</b>

                    <div style={{ maxHeight: "350px", overflow: "auto" }}>
                      {assistantData?.map((item, index) => (
                        item.readonly !== true && (
                          <div className='select_assistat_body' key={index}>
                            <div>
                              <b>{item.assistant_name}</b>
                            </div>

                            <div className="v3_modal_table_body_input" style={{ marginRight: "14px" }}>
                              <input
                                type="checkbox"
                                style={{ cursor: "pointer" }}
                                onChange={() => handleAssistantSelect(item)}
                                checked={selectedAssistantId.includes(item.assistant_id)} // Update checkbox based on state
                              />
                            </div>
                          </div>
                        )
                      ))}
                    </div>
                    {loader ?
                      <button className='v3_modal_table_button position-relative disabled-button'>
                        <spiner className='loader-v3'></spiner> <span style={{ padding: "10px 20px" }}>Save</span>
                      </button> :
                      <button onClick={uploadonKBSendTobackend} className='v3_modal_table_button position-relative'>
                        Save
                      </button>}

                    {/* <div className="form-group">
                      <form onSubmit={uploadonKBSendTobackend}>
                        <div>
                          <select className="w-100" name="assistantforkbupload" id="assistantforkbupload">
                            {assistantData?.map((item, index) => (
                              <option value={item.modal_name}>{item.assistant_name}</option>
                            ))}


                          </select>
                        </div>
                        <div className="text-center">
                          <button className="uploadbutton " type="submit">Save</button>
                        </div>
                      </form>
                    </div> */}
                  </div>
                </div>
              </div>
            </>}

            <ErrorHandlingModal isModalOpen={isModalOpen} justCloseIt={justCloseIt} errorMessage={errorMsg} type={errorType} />

          </div>
        </div>
      </div>

    </>
  );
};

export default Chat;
